import styled, { css } from "styled-components";
import { AnchorHTMLAttributes } from "react";

import { Text } from "src/components/Text";

export const Link = styled(Text).attrs({
  as: "a",
  color: "red"
})<AnchorHTMLAttributes<HTMLAnchorElement>>`
  ${({ theme }) => css`
    text-decoration: none;
    background-image: linear-gradient(${theme.colors.red}, ${theme.colors.red});
    background-position: 0% 82%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    transition: background-size .3s;

    &:hover, &:focus {
      background-size: 100% 1px;
      cursor: pointer;
    }
  `}
`;