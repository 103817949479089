import React from "react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { GlobalStyles } from "src/components/GlobalStyles";
import { breakpoint } from "src/utils/media-query";
import { getImageMetadata } from "src/utils/image";
import { Link } from "src/components/Link";
import { Text } from "src/components/Text";

interface HomePageProps extends PageProps {
  data: GatsbyTypes.HomePageQuery;
}

const HomePage: React.FC<HomePageProps> = ({ data }) => {
  const [ introductionImage, introductionImageAlt ] = getImageMetadata(data.strapiHomePage?.introduction_image);
  const destinationHref = data.strapiHomePage?.destination || "/about/the-house";

  return (
    <PageWrapper>
      <Helmet title="Dennis Severs' House">
        <link rel="stylesheet" href="https://use.typekit.net/acn7eot.css" />
      </Helmet>
      <GlobalStyles />
      <OutsideBorderBox>
        <InsideBorderBox>
          {introductionImage && (
            <IntroductionImage
              image={introductionImage}
              alt={introductionImageAlt}
            />
          )}
          <Link href={destinationHref}>
            <Text as="h1" color="red">
              Enter
            </Text>
          </Link>
        </InsideBorderBox>
      </OutsideBorderBox>
    </PageWrapper>
  );
};

export default HomePage;

const PageWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 1rem;

  ${breakpoint("tabletSP")} {
    padding: 2rem;
  }
`;

const OutsideBorderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex: 1;
  box-sizing: border-box;
  border: 3px solid ${({ theme }) => theme.colors.red};
`;

const InsideBorderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.colors.red};
  box-sizing: border-box;
  margin: 2px;
  position: relative;
`;

const IntroductionImage = styled(GatsbyImage)`
  height: 75vh;
  img {
    object-fit: contain!important;
  }
`;

export const query = graphql`
  query HomePage {
    strapiHomePage {
      destination
      introduction_image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;